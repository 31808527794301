// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-submarkets-submarket-page-tsx": () => import("./../../../src/components/submarkets/SubmarketPage.tsx" /* webpackChunkName: "component---src-components-submarkets-submarket-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-municipalities-tsx": () => import("./../../../src/pages/municipalities.tsx" /* webpackChunkName: "component---src-pages-municipalities-tsx" */),
  "component---src-pages-policy-strategy-tsx": () => import("./../../../src/pages/policy-strategy.tsx" /* webpackChunkName: "component---src-pages-policy-strategy-tsx" */),
  "component---src-pages-submarkets-tsx": () => import("./../../../src/pages/submarkets.tsx" /* webpackChunkName: "component---src-pages-submarkets-tsx" */)
}

